import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import DatePicker from 'vue2-datepicker';
import remedyDetails from './remedyDetails';
import assetViewDetails from '../assetViewDetails'
import remedyBatchDetails from './remedyBatchDetails';
import HistoryRecord from '@/app/components/onlineApproval/internalWorkFlow/history/historyRecord';
import ApprovalHistory from '@/app/components/onlineApproval/internalWorkFlow/history/approvalHistory';
import UploadDocument from '@/app/components/onlineApproval/internalWorkFlow/history/uploadDocument';
import sendForApproval from '@/app/components/onlineApproval/internalWorkFlow/history/sendForApproval';
import openKmAuthentication from '@/app/components/onlineApproval/internalWorkFlow/openKmAuthentication';
import Highcharts from 'highcharts';
import XLSX from 'xlsx';


export default {
  name: 'auditDetails',
  components: {
    appStrings,
    DatePicker,
    commonHelper,
    remedyDetails,
    assetViewDetails,
    remedyBatchDetails,
    HistoryRecord,
    ApprovalHistory,
    UploadDocument,
    sendForApproval,
    openKmAuthentication,
  },
  props: ['currentLoc','currentLocation','auditData','faLocation','locAccessFlag'],
  watch: {
    currentPage: function() {
      //   this.getassetMasterItems();
      this.getLogPhyAssetMatchData()
    },
    perPage: function() {
      this.currentPage = 1;
      this.getLogPhyAssetMatchData()
      //   this.getassetMasterItems();
    },
    currentPage1: function() {
      this.getRemedyHeader()
    },
    perPage1: function() {
      this.currentPage1 = 1;
      this.getRemedyHeader()
    }
  },
  data() {
    return {
      totalRows: null,
      totalRows1: null,
      currentPage: 1,
      currentPage1: 1,
      loader: false,
      responseMsg: '',
      isSuccess: false,
      showAlert: false,
      perPage: commonHelper.perPageRecord,
      perPage1: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOptionSelectedInvoice(),
      chartData1: [],
      createdBy: null,
      status: null,
      startDate:null,
      endDate:null,
      chartFlag: false,
      matchFlag:false,
      auditDetailsData: [],
      auditDetailsFields: [
        {
          key: 'selectBox',
          label: 'Select',
          stickyColumn: true,
          variant: 'info',
          class: 'stickyColumn col-sm-1 wid'
        },
        {
          key: 'asset_number',
          class: 'textDeco'
        },
        {
          key: 'serial_no',
          class: 'textDeco'
        },
        {
          key: 'asset_type',
          class: 'textDeco'
        },
        {
          key:'asset_details',
          class: 'textDeco'
        },
        {
          key: 'logical_assets',
          class: 'textDeco'
        },
        {
          key: 'physical_assets',
          class: 'textDeco'
        },
        {
          key: 'logical_loc_name',
          class: 'textDeco'
        },
        {
          key: 'physical_loc_name',
          class: 'textDeco'
        },
        {
          key: 'status',
          class: 'textDeco'
        },
        {
          key: 'reason',
          label:'Mismatch Reason',
          class: 'textDeco'
        },
        {
          key: 'remedy',
          class: 'textDeco'
        },
        // {
        //   key: 'remarks',
        //   class: 'textDeco'
        // }
      ],
      auditAssetDetailsData:[
        {
          audit_details:"Compliance Score"
        },
        {
          audit_details:"Total Remediation Required"
        },
        {
          audit_details:"Remediation Done"
        },
      
      ],
      auditAssetDetailsFields:[
        {
          key: 'audit_details',
          class: 'textDeco'
        },
        {
          key: 'value',
          class: 'textDeco'
        },
      ],
      remedyHeaderData:[],
      remedyHeaderFields:[
        {
          key:'remedy_batch_number',
        },
        {
          key:'approval_status'
        },
        {
          key:'audit_batch_number'
        },
        {
          key:'created_by'
        },
        {
          key:'creation_date'
        },
        // { 
        //   key: 'remove', 
        //   stickyColumn: true 
        // },
      ],
      selectAllCheckBox: false,
      selectedRow: [],
      editMode: false,
      mismatchedActive: false,
      matchedActive: false,
      notFoundActive: false,
      checkFlag:false,
      showHideRemedyModalFlag:false,
      showHideAssetDetailsModalFlag:false,
      assetNum:null,
      binIds:[
      ],
      binMatchingIds:[],
      asstStatus:[],
      matchedLog : 0,
      mismatchedLog:0,
      notfound :0,
      remedyMenuId:null,
      showHideRemedyBatchModalFlag:false,
      remedyHdrData:null,
      historyType:null,
      showHistory: false,
      showOpenKmModal: false,
      openkmAuthToken:null,
      batchId:null,
      eventId:null,
      remStatus:null,
      accessButtons:{},
      reqId:null,
      matchingStatus:null,
      batchStat:null,
      remData:null,
      payload:{},
      downloadAuditData:null
    };
  },
  validations: {},
  mounted() {
    this.getAuditHeader()
    this.getAuditHeaderDetails()
    this.getRemedyMenuID()
    this.getRemedyHeader()
    this.getAuditDataPoints()
    this.getRemedyBatches()
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save') {
          //   this.showHideAddFaLocationModal(true);
        } else if (actionName === 'edit' || actionName === 'update') {
          this.editMode = true;
          this.eventBus.$emit('auditFlag', this.editMode);
        }    else if (
          actionName === 'download'
        ) {
            this.downloadAuditReportData('fa-AuditReport',this.downloadAuditData);
          }
      }
    });
    this.getDetails();
  },
  methods: {
    totalLines() {
      let matchd=this.matchedLog;
      let misMatchd=this.mismatchedLog;
      let notFound=this.notfound;
      this.chartData1 = [
        ['Matched', matchd],
        ['Mismatched', misMatchd],
        ['Not Found', notFound]
      ];
      this.chartFlag = true;
      this.showCharts()
    },
    selectAllBoxChecked(flag) {
      this.selectedRow = [];
      if (flag) {
        this.auditDetailsData = this.auditDetailsData.map((data, index) => {
          if(data.status=='MISMATCHED' && data.remedy==null){
            data.selectBox = this.selectAllCheckBox;
          if (this.selectAllCheckBox) {
            this.selectBoxChecked(data.selectBox, index);
          }
        }
          return data;
        });
        this.selectedRow = this.auditDetailsData;
      } else {
        this.auditDetailsData = this.auditDetailsData.map((data, index) => {
          data.selectBox = this.selectAllCheckBox;
          if (this.selectAllCheckBox) {
            this.selectBoxChecked(data.selectBox, index);
          }
          return data;
        });
        this.selectedRow = [];
      }
      if(!this.selectAllCheckBox){
        this.checkFlag = false;
      }

      this.auditDetailsData.map(item=>{
        if(item.status=="Matched"){
              item.selectBox=false
        }
      })

    },
    selectBoxChecked(flag, index, item, assetNum) {
      this.auditDetailsData[index].selectBox = flag;
      if (flag === true && this.auditDetailsData[index].status=='MISMATCHED') {
        this.selectedRow.push(item);
      } else {
        this.selectedRow = this.selectedRow.filter(
          el => el.asset_number !== assetNum
        );
      }
      if (this.selectedRow.length > 0 || this.selectAllCheckBox) {
        this.checkFlag = true;
      } else {
        this.checkFlag = false;
      }

    },
    getAuditData(data) {
        this.auditDetailsData=[];
        if(data){
          this.mismatchedActive =false
          this.matchedActive =false
          this.notFoundActive = false
          this.asstStatus=["MATCHED","MISMATCHED","NOTFOUND"]
          this.selectAllCheckBox=false
          this.auditDetailsData.map(ele=>{
            ele.selectBox=false
          })
        }
      if (
        this.mismatchedActive == true &&
        this.matchedActive == false &&
        this.notFoundActive == false
      ) {
        this.asstStatus=["MISMATCHED"]
      } else if (
        this.mismatchedActive == true &&
        this.matchedActive == true &&
        this.notFoundActive == false
      ) {
        this.asstStatus=["MATCHED","MISMATCHED"]
      } else if (
        this.mismatchedActive == true &&
        this.matchedActive == true &&
        this.notFoundActive == true
      ) {
        this.asstStatus=["MATCHED","MISMATCHED","NOTFOUND"]
      } else if (
        this.mismatchedActive == false &&
        this.matchedActive == false &&
        this.notFoundActive == false
      ) {
        this.asstStatus=["MATCHED","MISMATCHED","NOTFOUND"]
      } else if (
        this.mismatchedActive == false &&
        this.matchedActive == true &&
        this.notFoundActive == false
      ) {
        this.asstStatus=["MATCHED"]
      } else if (
        this.mismatchedActive == false &&
        this.matchedActive == false &&
        this.notFoundActive == true
      ) {
        this.asstStatus=["NOTFOUND"]
      } else if (
        this.mismatchedActive == false &&
        this.matchedActive == true &&
        this.notFoundActive == true
      ) {
        this.asstStatus=["MATCHED","NOTFOUND"]
      } else if (
        this.mismatchedActive == true &&
        this.matchedActive == false &&
        this.notFoundActive == true
      ) {
        this.asstStatus=["MISMATCHED","NOTFOUND"]
      }
      this.getLogPhyAssetMatchData()
    },
    getDetails(){
        // this.auditDetailsData=this.data
    },
    showHideRemedyModal(flag){
    if(flag){
      this.selectedRow=this.selectedRow.filter(ele => ele.status=="MISMATCHED" && ele.remedy==null)
    }else{
      this.selectedRow=[]
    }
    this.showHideRemedyModalFlag=flag
    if(flag && !this.locAccessFlag) {
      this.accessButtons = {
        deleteAllow: false,
        downloadAllow: false,
        uploadAllow: false,
        editAllow: false,
        addAllow: false,
        printAllow: false,
        approvalAllow: false
      };
    }else{
      this.accessButtons = {
        deleteAllow: false,
        downloadAllow: false,
        uploadAllow: false,
        editAllow: true,
        addAllow: true,
        printAllow: false,
        approvalAllow: false
      };
    }
    },
    showHideRemedyBatchModal(flag,status){
      this.showHideRemedyBatchModalFlag=flag
      if(status){
        this.batchStat=status
      }
      if(flag && !this.locAccessFlag) {
        this.accessButtons = {
          deleteAllow: false,
          downloadAllow: false,
          uploadAllow: false,
          editAllow: false,
          addAllow: false,
          printAllow: false,
          approvalAllow: false
        };
      }else{
        this.accessButtons = {
          deleteAllow: false,
          downloadAllow: false,
          uploadAllow: false,
          editAllow: false,
          addAllow: true,
          printAllow: false,
          approvalAllow: false
        };
      }
      },
     rowSelected(data){
        this.batchStat=null
        this.showHideRemedyBatchModalFlag=true
        this.remedyHdrData=data
        if(this.remedyHdrData.approval_status=="SCRAP"){
          this.batchStat=this.remedyHdrData.approval_status
        }
        this.batchId=data.remedy_batch_id
      },
    showHideAssetDetailsModal(flag,asstNum){
      this.assetNum=asstNum
      this.showHideAssetDetailsModalFlag=flag
      if(flag && !this.locAccessFlag) {
        this.accessButtons = {
          deleteAllow: false,
          downloadAllow: false,
          uploadAllow: false,
          editAllow: false,
          addAllow: false,
          printAllow: false,
          approvalAllow: false
        };
      }
    },
    getLogPhyAssetMatchData() {
       this.payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        faLoc:Number(this.faLocation.value),
        bin_ids:this.binIds.toString(),
        audit_hdr_id:this.auditData.audit_hdr_id,
        status:this.asstStatus.toString()
      };
      this.loader = true;
      this.$store
        .dispatch('assets/getLogPhyAssetMatchData', this.payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.auditDetailsData=resp.data.data.page;
            this.totalRows=resp.data.data.total_elements;
             this.downloadAuditData=this.auditDetailsData.map(data =>
              {
                return{
                  asset_number:data.asset_number,
                  serial_no:data.serial_no,
                  asset_type:data.asset_type,
                  reason:data.reason,
                  logical_assets:data.logical_assets,
                  physical_assets:data.physical_assets,
                  logical_loc_name:data.logical_loc_name,
                  physical_loc_name:data.physical_loc_name,
                  status:data.status,
                  mismatch_reason:data.reason,
                  start_date:data.start_date,
                  creation_date:data.creation_date,
                  remediation_status:data.remedy

                }
              }
            )
            this.getAuditDataPoints()
          }
        })
        .catch(() => {
          this.loader = false;
          this.asstStatus=[]
        });
    },
    assetMatching(){
      const payload = {
        audit_hdr_id:this.auditData.audit_hdr_id,
        bin_ids:this.binMatchingIds,
        fa_location_id:Number(this.faLocation.value)
      };
      this.loader = true;
      this.$store
        .dispatch('assets/postLogPhyAssetMatchData', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.reqId=resp.data.data
            this.getSubmitRequest()
            
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getSubmitRequest() {
      this.loader = true;
      this.$store
          .dispatch('template/getSubmitRequest', this.reqId)
          .then(resp => {
              this.loader = false;
              if (resp.status === 200) {
                  this.matchingStatus = resp.data.data[0].status;
                  if(this.matchingStatus=="COMPLETED"){
                    this.reqId=null
                    this.getAuditData();
                    // this.getAuditDataPoints();
                  }
             
              }
          })
          .catch(() => {
              this.loader = false;
          });
  },
    getAuditHeaderDetails() {
      const payload = {
        hdrId: this.auditData.audit_hdr_id
      };
      this.loader = true;
      this.$store
        .dispatch('assets/getAuditHeaderDetails', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            resp.data.data.page.map(ele=>{
              this.binIds.push(ele.bin_id)
              this.binMatchingIds.push({bin_id:ele.bin_id})
            })
            // this.assetMatching()
            if(this.auditData.last_updation_date){
              this.getAuditData()
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getAuditHeader(){
      const payload = {
        // _page: this.currentPage - 1,
        // _limit: this.perPage,
        hdrId: this.auditData.audit_hdr_id
      };
      this.loader = true;
      this.$store
        .dispatch('assets/getAuditHeader', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
             this.createdBy=resp.data.data[0].created_by;
             this.status=resp.data.data[0].audit_status;
             this.startDate=resp.data.data[0].start_date             
             this.endDate=resp.data.data[0].end_date
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getAuditDataPoints(){
      const payload = {
        auditHdrId: this.auditData.audit_hdr_id
      };
      this.loader = true;
      this.$store
        .dispatch('assets/assetAuditDataPoints', payload)
        .then(resp => {
          if (resp.status === 200) {
           this.matchedLog = resp.data.data.matched;
           this.mismatchedLog = resp.data.data.mismatched;
           this.notfound = resp.data.data.notfound;
           this.totalLines();
           this.loader = false;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getRemedyMenuID() {
      const payload = {
        remedyName: "AUDIT REMEDY"
      };
      this.loader = true;
      this.$store
        .dispatch('assets/getRemedyMenuId', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.remedyMenuId=resp.data.data.menu_id
            if(this.remedyMenuId){
                this.getEventId()
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getEventId() {
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/getEventId', this.remedyMenuId)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.eventId = response.data.data;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getRemedyHeader(id){
      if(id){
        this.remStatus="sendForApproval"
      }
      const payload = {
        auditHdrId: this.auditData.audit_hdr_id,
        params:{
          _page: this.currentPage1 - 1,
          _limit: this.perPage1,
        }
      };
      this.loader = true;
      this.$store
        .dispatch('assets/getRemedyHeader', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.remedyHeaderData=resp.data.data.page
            this.totalRows1=resp.data.data.total_elements            
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    showHideHistory(flag, historyType) {
      this.showHistory = flag;
      this.historyType = historyType;
  },
  showHideOpenKmModal(flag) {
    // to open km modal
    this.showOpenKmModal = flag;
  },
  setOpenKmToken(token) {
    // to set token in km
    this.showHideOpenKmModal(false);
      this.openkmAuthToken = token;
      this.showHideHistory(true, 'uploadDocument');
    },
    checkOpenKmPasswordSaved() {
      // to check password save or not
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/checkSavedPassword')
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            if (response.data.data) {
              this.getOpenKmAuthToken();
            } else {
              this.showHideOpenKmModal(true);
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getOpenKmAuthToken() {
      // to get auth token for open km
      const payload = {
        password: null,
        saved_password_flag: true,
        user_name: null
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/openKmAuthenticate', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.openkmAuthToken = response.data.data.token;
              this.showHideHistory(true, 'uploadDocument');
            }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    showCharts(){
      Highcharts.chart('chart-container2', {
        chart: {
          type: 'pie',
          height: 340,
          borderRadius:18,
        },  
        title: {
          text: this.currentLoc.text,
          style:{
            color: 'white',
            fontWeight:'bold'
          }
        },
        plotOptions: {
          pie: {
             allowPointSelect: true,
              cursor: 'pointer',          
            dataLabels: {
              enabled: true,
              color:'white',
              format:  '<b>{point.name}</b><br>{point.percentage:.1f}%',
              style: {
                fontFamily: 'Open Sans',
                fontWeight:'x-small',
                textOutline:'none'
              },
              distance:30,
            }
          }
        },
          tooltip:{
         style: {
                fontFamily: 'Open Sans',
              }
        },
        credits: {
          enabled: false
        },
        exporting: {
          enabled: false
        },
        series: [
          {
            name: 'Data',
            size: '70%',
            data: this.chartData1,
            innerSize: '30%',
          }
        ]
      });
    },
  closeAuditBatch(){
    const payload = {
      auditHdrId:this.auditData.audit_hdr_id,
    };
    this.loader = true;
    this.$store
      .dispatch('assets/closeAuditBatch', payload)
      .then(resp => {
        this.loader = false;
        if (resp.status === 200) {
          this.getAuditHeader()
          this.$emit('getAuditHistory')
        }
      })
      .catch(() => {
        this.loader = false;
      });
  },
  getRemedyBatches(){
    const payload = {
      auditHdrId: this.auditData.audit_hdr_id,
    };
    this.loader = true;
    this.$store
      .dispatch('assets/getRemedyBatches', payload)
      .then(resp => {
        this.loader = false;
        if (resp.status === 200) {
          this.remData=resp.data.data.page
        }
      })
      .catch(() => {
        this.loader = false;
      });
  },
   downloadAuditReportData(compName,requestData) {
          const requestedData = requestData;
          const dataToDownload = requestedData.map(data => {
            let newData = {};
            for (const key in data) {
              const formatedKeyName = key.replace(/_/g, ' ').toUpperCase();
              Object.assign(newData, { [formatedKeyName]: data[key] });
            }
            return newData;
          });
          const data = XLSX.utils.json_to_sheet(dataToDownload);
          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, data, 'data');
          XLSX.writeFile(
            wb,
            `${compName}-${new Date().toLocaleDateString()}.xlsx`
          );
  },
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};

import commonHelper from '@/app/utility/common.helper.utility';
import assetStatusUpdate from "./assetStatusUpdate"
import assetViewDetails from '../../assetAudit/assetViewDetails'
// import commonHelper from '@/app/utility/common.helper.utility';
import loginHistory from '../../myAssets/loginHistory'

export default {
  name: 'assetStatus',
  props:['assetData','locId','locAccessFlag'],
  components:{assetStatusUpdate,assetViewDetails,loginHistory},
  watch: {
    currentPage: function() {
        this.getAssetStatus();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getAssetStatus();
    },
    currentPage1: function() {
      this.getAssetHistory(this.historyAssetNo);
  },
  perPage1: function() {
    this.currentPage1 = 1;
      this.getAssetHistory(this.historyAssetNo);
  },
  },
  data() {
    return {
      totalRows: null,
      totalRows1:null,
      currentPage: 1,
      currentPage1: 1,
      loader: false,
      responseMsg: '',
      isSuccess: false,
      showAlert: false,
      perPage: commonHelper.perPageRecord,
      perPage1: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOptionSelectedInvoice(),
      siteName:null,
      locSearch:null,
      department:{
        text:null,
        value:null
      },
      isOpen: false,
      showValueSetModal:false,
      currentLoc: {
        text: null,
        value: null
      },
      assetStatusData: [
      ],
      assetStatusFields: [
        {
          key: 'selectRadio',
          label: 'Select',
          stickyColumn: true,
          class: 'selectRadio',
          variant: 'primary'
        },
        {
          key:'asset_num',
          label: 'Asset Number',
          class:"alignItems"
        },
        {
          key:'serial_no',
          label: 'Serial Number',
          class:"alignItems"
        },
        {
          key: 'asset_type',
          class:"alignItems"
        },
        {
          key: 'asset_details',
          class:"alignItems"
        },
        {
          key: 'status',
          class:"alignItems",
        },
        {
          key:'login_History',
          class:this.assetData.assetCategory === 'HHD'?'alignItems':'alignItems d-none',
        }
      ],
      statusHistoryData:[
      ],
      statusHistoryFields:[
        {
          key:'Status',
          class:"alignItemsHistory"
        },
        {
          key:'Status_detail',
          class:"alignItemsHistory"
        },
        {
          key:'location_name',
          label:'Location',
          class:"alignItemsHistory"
        },
        {
          key:'ticket_number',
          class:"alignItemsHistory"
        },
        {
          key:'Status_date',
          class:"alignItemsHistory"
        },
        {
          key:'updated_by_name',
          label:"Updated By",
          class:"alignItemsHistory"
        }
      ],
      category:null,
      status:null,
      qty:null,
      showStatusModalFlag:false,
      assetN:null,
      rowData:null,
      assetNo:null,
      editMode:false,
      historyAssetNo:null,
      historySerialNo:null,
      historyIndex:null,
      showAssetDetailsModalFlag:false,
      assetNum:null,
      accessButtons: {},
      visFlag:false,
      serialNo:null,
      payload:null,
      showLoginHistoryModal:false,
    };
  },
  mounted() {
    this.getAssetStatus()
    if(this.assetData){
        this.category=this.assetData.assetCategory;
        this.status=this.assetData.assetStatus;
        this.qty=this.assetData.assetQty
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save') {
          //   this.showHideAddFaLocationModal(true);
        } else if (actionName === 'edit' || actionName === 'update' && (!this.showAssetDetailsModalFlag || !this.showStatusModalFlag)) {
          this.editMode = true;
        }
        else if (
          actionName === 'download' && !this.showAssetDetailsModalFlag
        ) {
            this.loader = true;
            const downloadpayload = { ...this.payload };
            downloadpayload.queryParams._limit = this.totalRows;
            this.downloadExcel.downloadData(
              downloadpayload,
              'assets/getDashboardAssetStatusDetails',
              'fa-getAssetDetails',
              () => (this.loader = false),
            );
        }
      }
    });
  },
  methods: {
    hide() {},
    showStatusModal(flag,index,data){
      this.showStatusModalFlag=flag;
      if(this.showStatusModalFlag){
        this.assetN=data.asset_num
        this.rowData=data
        this.rowData.doc_num=data.asset_num;
      }else{
        this.getAssetStatus()
      }
      if(flag && !this.locAccessFlag) {
        this.accessButtons = {
          deleteAllow: false,
          downloadAllow: false,
          uploadAllow: false,
          editAllow: false,
          addAllow: false,
          printAllow: false,
          approvalAllow: false
        };
      }else{
        this.accessButtons = {
          deleteAllow: false,
          downloadAllow: false,
          uploadAllow: false,
          editAllow: true,
          addAllow: true,
          printAllow: false,
          approvalAllow: false
        };
      }
    },
    // selectBoxChecked(flag,index, item) {
    //   if(flag){
    //     this.historyAssetNo=item.asset_number;
    //     this.historySerialNo=item.serial_number;
    //     this.historyIndex=index
    //   }else{
    //     this.historyIndex=null
    //   }
    
    // },
    selectedRadio(flag,index,item) {
      if(flag){
        this.historyAssetNo=item.asset_num;
        this.historySerialNo=item.serial_no;
        this.historyIndex=index
        this.visFlag=true
        this.getAssetHistory(item.asset_num)
      }else{
        this.historyIndex=null
        this.visFlag=false
      }
    },
    changeFlag(content){
      if(this.historyAssetNo==null){
        this.$bvToast.toast(content, {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
      }
      // this.visFlag=false
    },
    getAssetHistory(assetNum){
      const payload = {
          assetNo:assetNum,
          _page: this.currentPage1 - 1,
          _limit: this.perPage1,
      };
      this.loader = true;
      this.$store
        .dispatch('assets/getAssetHistoryDetails', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.statusHistoryData = resp.data.data.page;
            this.totalRows1 = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    showAssetDetailsModal(flag,asstDetail){
      this.showAssetDetailsModalFlag=flag;
      if (flag) {
        this.assetNum=asstDetail;
        this.assetNum.doc_num=asstDetail.asset_num;
    }
    if(flag && !this.locAccessFlag) {
      this.accessButtons = {
        deleteAllow: false,
        downloadAllow: false,
        uploadAllow: false,
        editAllow: false,
        addAllow: false,
        printAllow: false,
        approvalAllow: false
      };
    }else{
      this.accessButtons = {
        deleteAllow: false,
        downloadAllow: false,
        uploadAllow: false,
        editAllow: false,
        addAllow: false,
        printAllow: false,
        approvalAllow: false
      };
    }
  },
  getAssetStatus() {
    this.visFlag=false;
    this.historyAssetNo=null;
    this.payload = {
      params:{
        hrLocId:this.locId
      },
      queryParams:{
      _page: this.currentPage - 1,
      _limit: this.perPage,
      assetCat:this.assetData.assetCategory,
      status:this.assetData.assetStatus,
      assetNo:this.assetNo,
      serialNo:this.serialNo,
    }
    };
    this.loader = true;
    this.$store
      .dispatch('assets/getDashboardAssetStatusDetails', this.payload)
      .then(resp => {
        this.loader = false;
        if (resp.status === 200) {
          this.assetStatusData = resp.data.data.page;
          this.totalRows = resp.data.data.total_elements;
          // this.qty=resp.data.data.total_elements
        }
      })
      .catch(() => {
        this.loader = false;
      });
  },
    // openCreateGrnModal(){

    // },
    // openValueSetModal(vsetCode) {
    //   this.vsetCode = vsetCode;
    //   this.showValueSetModal = true;
    //   this.setTimeVsetCode = setTimeout(() => {
    //     const vSetData = {
    //       valueSetName: vsetCode,
    //       multiFlag: null
    //     };
    //     this.eventBus.$emit('valueSetCode', vSetData);
    //   }, 0);
    // },
    // selectedvalueSet(item) {
    //   if(this.vsetCode === appStrings.VALUESETTYPE.FA_DEPARTMENT){
    //       this.department.text=item.value_meaning;
    //       this.department.value=item.value_code;
    //   }
    //   else if(this.vsetCode === appStrings.VALUESETTYPE.LOCATION){
    //     this.currentLoc = {
    //       value: item.value_set_dtl_id,
    //       text: item.value_code,
    //     };
    //     this.erpLoctnOrgId = item.erp_loc_org_id;
    //   }
    // },
    // closeValueSetModal() {
    //   this.showValueSetModal = false;
    // },
    // clearVsetValues(vsetCode) {
    //   this.vsetCode=vsetCode
    //   if(this.vsetCode===appStrings.VALUESETTYPE.FA_DEPARTMENT){
    //     this.department.text= null,
    //     this.department.value= null
    //   }
    //   else if(this.vsetCode === appStrings.VALUESETTYPE.LOCATION){
    //     this.currentLoc = {
    //       value: null,
    //       text: null
    //     }
    //   }
    // },
    showHideLoginHistoryModal(flag, item) {
      this.rowData = item;
      this.showLoginHistoryModal = flag;
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};

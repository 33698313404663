import appStrings from '@/app/utility/string.utility';
import commonHelper from '@/app/utility/common.helper.utility';
import DatePicker from 'vue2-datepicker';
import URL_UTILITY from '../../../../utility/url.utility';
import addEditEwayBill from '../../generateEwayBill/addEditEwayBill';

export default {
  name: 'SearchAsset',
  components: {
    DatePicker,
    addEditEwayBill
  },
  props: ['batchData', 'currentLoc', 'batchType', 'locAccessFlag'],
  watch: {
    batchCurrentPage() {
      this.getTransferBatch();
    },
    batchPerPage() {
      this.batchCurrentPage = 1;
      this.getTransferBatch();
    },
    assetCurrentPage() {
      this.getTransferredAssets();
    },
    assetPerPage() {
      this.assetCurrentPage = 1;
      this.getTransferredAssets();
    },
    batchStatus: function() {
      if (this.batchStatus === 'Incomplete') {
        this.transferAssetToFields.unshift({
          key: 'checkBox',
          stickyColumn: true,
        });
      } else if(this.batchStatus !== 'Incomplete'){
        this.transferAssetToFields.unshift({
          key: 'checkBox',
          stickyColumn: true,
          class: 'd-none'
        });
      }
      else if (this.batchStatus === 'Rejected') {
        this.editMode = false;
      }
    },
    tags() {
      this.adjustTagsWidth();
    }
  },
  data() {
    return {
      unsubscribe: null,
      manufacturer: '',
      serialNo: '',
      modelNo: '',
      purchaseDate: [],
      status: {
        text: null,
        value: null
      },
      poNumber: '',
      description: '',
      value: '',
      batchStatus: null,
      assetNo: '',
      loader: false,
      batchNo: null,
      type: {
        text: null,
        value: null
      },
      showAdvSearchModal: false,
      showValueSetModal: false,
      parent_value_set_id: null,
      legalEntity: {
        text: null,
        value: null
      },
      bookType: {
        text: null,
        value: null
      },
      locationFrom: {
        text: null,
        value: null
      },
      locationTo: {
        text: null,
        value: null
      },
      category: {
        text: null,
        value: null
      },
      valueFrom: null,
      valueTo: null,
      invoice_num: null,
      transferAssetFromRows: null,
      transferAssetFromData: [],
      transferAssetFromFields: [
        {
          key: 'checkBox',
          stickyColumn: true
        },
        {
          key: 'doc_num',
          label: this.batchType === 'IT' ? 'Asset Number' : 'Tag Number'
        },
        {
          key: 'description'
        },
        {
          key: 'actual_cost',
          label: 'Value'
        },
        {
          key:'serial_no',
          label:'Serial Number'
        },
        {
          key: 'oracle_id',
          label: 'Asset Id'
        },
        {
          key: 'asset_category_name',
          label: 'Category'
        },
        {
          key: 'hsn_sac_code',
          label: 'HSN/SAC Code'
        },
        {
          key: 'awb_no',
          label: 'AWB No.'
        },
        {
          key: 'remark'
        },
        {
          key: 'damage_type',
          class: this.batchData.transfer_type =='RMA' && this.batchType=="IT" ? '' : 'd-none'
        },
        {
          key: 'book_type_code',
          label: 'Book Type'
        }
      ],
      transferAssetToRows: null,
      transferAssetToData: [],
      transferAssetToFields: [
        {
          key: 'doc_num',
          label: this.batchType === 'IT' ? 'Asset Number' : 'Tag Number'
        },
        {
          key: 'gross_book_value',
          label: 'Value'
        },
        {
          key: 'tax_amount'
        },
        {
          key: 'current_cost'
        },
        {
          key: 'tax_cat_name',
          label: 'Tax Category'
        },
        {
          key: 'description'
        },
        {
          key: 'hsn_sac_code',
          label: 'HSN/SAC Code'
        },
        {
          key: 'awb_no',
          label: 'AWB No.'
        },
        {
          key:'serial_no',
          label:'Serial Number'
        },
        {
          key: 'category_name',
          label: 'Asset Category'
        },
        {
          key: 'oracle_id',
          label: 'Asset Id'
        },
        {
          key: 'transfer_description',
        },
        {
          key: 'damage_type',
          class: this.batchData.transfer_type =='RMA' && this.batchType=="IT" ? '' : 'd-none'
        },
        {
          key: 'post_to_oracle'
        },
        {
          key: 'error_msg'
        }
      ],
      transferHdrId: null,
      batchPerPage: 200, //commonHelper.perPageRecord,
      batchPageOptions: commonHelper.getPageOption(),
      batchCurrentPage: 1,
      assetPerPage: 200, //commonHelper.perPageRecord,
      assetPageOptions: commonHelper.getPageOption(),
      assetCurrentPage: 1,
      defaultHsnCode: null,
      defaultAwbNo: null,
      defaultRemarks: null,
      defaultTaxGroup: null,
      defaultTaxGroupId: null,
      defaultTaxCategory: null,
      defaultTaxCategoryId: null,
      allBatch: false,
      allTransferredBatch: false,
      responseMsg: '',
      assetIndex: null,
      taxType: '',
      fromGstn: null,
      toGstn: null,
      moduleId: null,
      invoiceNumber: null,
      hsnValid: false,
      showExcelUploadModal: false,
      transferBatchId: null,
      requestId: null,
      templateId: null,
      irnRequestId: null,
      irnTemplateId: null,
      irnRequestNumId: null,
      irnRequestStatus: null,
      irnReqId: null,
      requestNumId: null,
      requestStatus: null,
      downloadReport: URL_UTILITY.getDownloadReportUrl,
      downloadLog: URL_UTILITY.getDownloadLOGUrl,
      reqId: null,
      processStatus: null,
      disableFlag: false,
      locationFromAddress: null,
      locationToAddress: null,
      irnNumber: null,
      irnStatus: null,
      irnError: null,
      existingHsnCode: '',
      updatedHsnCode: '',
      isPrintInvoice: false,
      limit: null,
      rack: null,
      shelf: null,
      bin: null,
      itemType: {
        value: null,
        text: null
      },
      itemDesc: null,
      quantity: null,
      unitPrice: null,
      amount: null,
      tax: 18,
      hsnCode: null,
      ewayBillNo: null,
      address1: null,
      address2: null,
      city: null,
      pin: null,
      country: null,
      state: null,
      itemCode: {
        value: null,
        text: null
      },
      consumablesData: [],
      consumablesFields: [
        {
          key: 'selectBox',
          label: 'Select',
          stickyColumn: true,
          variant: 'info'
        },
        {
          key: 'delete',
          label: 'Delete',
          stickyColumn: true
        },
        {
          key: 'item_type',
          class: 'text-center'
        },
        {
          key: 'item_code',
          class: 'text-center'
        },
        {
          key: 'hsn_sac_code',
          label: 'HSN Code',
          class: 'text-center'
        },
        {
          key: 'item_description',
          class: 'text-center'
        },
        {
          key: 'unit_price',
          class: 'text-center'
        },
        {
          key: 'quantity',
          class: 'text-center'
        },
        {
          key: 'AMOUNT',
          class: 'text-center'
        },
        {
          key: 'tax_cat_name',
          label: 'Tax Category'
        },
        {
          key: 'tax_amount',
          class: 'text-center'
        }
      ],
      addConsumablesData: [
        {
          item_type: '',
          item_code: '',
          hsn_sac_code: '',
          item_description: '',
          quantity: '',
          unit_price: '',
          amount: '',
          tax: ''
        }
      ],
      addConsumablesFields: [
        {
          key: 'add_consumables',
          label: 'Add',
          stickyColumn: true
        },
        {
          key: 'delete_consumables',
          label: 'Delete',
          stickyColumn: true
        },
        {
          key: 'item_type',
          class: 'text-center'
        },
        {
          key: 'item_code',
          class: 'text-center'
        },
        {
          key: 'hsn_sac_code',
          label: 'HSN Code',
          class: 'text-center'
        },
        {
          key: 'item_description',
          class: 'text-center'
        },
        {
          key: 'unit_price',
          class: 'text-center'
        },
        {
          key: 'quantity',
          class: 'text-center'
        },
        {
          key: 'amount',
          class: 'text-center'
        }
      ],
      allConsumables: false,
      transferType: null,
      selectedTransferAssetFromData: [],
      viewMoreFlag: false,
      pdfDownloadingLink: null,
      printInvoiceNumberFlag: false,
      showOpenEwayBillModal: false,
      assetNumber: null,
      ewayBillData: [],
      ewayBillFields: [
        {
          key: 'selectRadio',
          label: 'Select',
          stickyColumn: true,
          variant: 'primary'
        },
        {
          key: 'ewb_no',
          label: 'Eway Bill Num'
        },
        {
          key: 'invoice_date',
          label: 'Date'
        },
        {
          key: 'ewb_valid_from',
          label: 'Valid From'
        },
        {
          key: 'ewb_valid_to',
          label: 'Valid Until'
        },
        {
          key: 'trans_name'
        },
        {
          key: 'trans_mode'
        },
        {
          key: 'distance'
        },
        {
          key: 'trans_doc_dt'
        },
        {
          key: 'trans_doc_no',
          label: 'LR Number'
        },
        {
          key: 'veh_no'
        },
        {
          key: 'veh_type'
        },
        {
          key: 'remarks'
        },
        {
          key: 'status'
        },
        {
          key: 'cancel_status'
        },
        {
          key: 'cancel_date'
        }
      ],
      remarks: '',
      selectedPickupTime: null,
      minDate: this.getCurrentDateTime(),
      showOpenPickupRequestModal: false,
      pickupDate: null,
      pickupTime: null,
      showOpenShipmentTrackingModal: false,
      shipmentTrackingData: [],
      shipmentTrackingFields: [
        {
          key: 'scanDate',
          class: 'text-center'
        },
        {
          key: 'scanTime',
          class: 'text-center'
        },
        {
          key: 'ScanType',
          class: 'text-center'
        },
        {
          key: 'Scan',
          class: 'text-center'
        },
        {
          key: 'StatusDate',
          class: 'text-center'
        },
        {
          key: 'StatusTime',
          class: 'text-center'
        },
        {
          key: 'ScannedLocation',
          class: 'text-center'
        },
        {
          key: 'Instructions',
          class: 'text-center'
        },
        {
          key: 'StatusCode',
          class: 'text-center'
        }
      ],
      loading: false,
      defaultTaxGroupConsumables: null,
      defaultTaxGroupConsumablesId: null,
      defaultTaxCategoryConsumables: null,
      defaultTaxCategoryConsumablesId: null,
      showOpenGenerateAwbModal: false,
      name: null,
      phone: null,
      editMode: true,
      assetItem: {
        value: null,
        text: null
      },
      processorType: {
        value: null,
        text: null
      },
      valuetype: null,
      itemId: null,
      username: null,
      userphone: null,
      consumableIndex: null,
      regexPattern: /^[a-zA-Z ]*$/,
      nameFlag:false,
      regexPattern1:/^[0-9]*$/,
      numFlag:false,
      active:false,
      awbIrnflag:false,
      awbNum:null,
      serialNum:null,
      serialNumAssetStatus:null,
      batchNum:null,
      batchSatus:null,
    };
  },
  computed: {
    selectedTransferAsset() {
      const selectionCount = this.transferAssetFromData.filter(
        key => key.checkBox
      );
      return selectionCount.length;
    },
    selectedReceiveAsset() {
      const selectionCount = this.transferAssetToData.filter(
        key => key.checkBox
      );
      return selectionCount.length;
    },
    disableTaxCategory() {
      const selectedRecord = this.transferAssetToData.some(t => t.checkBox);
      return (
        !this.checkAllTransferredBatch ||
        !selectedRecord ||
        !this.checkTaxApplicable()
      );
    },
    disableTaxCategoryForConsumables() {
      const selectedRecord = this.consumablesData.some(t => t.selectBox);
      return (
        !this.checkAllConsumablesRow ||
        !selectedRecord ||
        !this.checkTaxApplicable()
      );
    }
  },

  mounted() {
    if (this.batchData) {
      this.getIrnProcessById(this.batchData.trf_batch_id);
      this.fillBatchDetails(this.batchData);
      this.getGstnFromLocation(this.locationFrom.value, 'from_loc');
      this.getGstnFromLocation(this.locationTo.value, 'to_loc');
    }
    this.moduleId = this.$store.state.shared.moduleId;
    this.setDefaultTaxGroup();
    this.getTransferredAssets();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.addTransferAssetsDetails();
        }
        if (actionName === 'delete') {
          this.deleteTransferAssetDetails();
        }
        if (actionName === 'print') {
          this.showExcelUploadModal = true;
        }
        if (actionName === 'download' && !this.showAddTransferAssetModal) {
          this.loader = true;
          /**
           * @param(payload, 'action name', 'file name')
           */
          const downloadpayload = { ...this.payload };
          downloadpayload._limit = this.transferAssetFromRows;
          this.downloadExcel.downloadData(
            downloadpayload,
            'assets/getAllAssetsForTransfer',
            'transfer-asset',
            () => (this.loader = false)
          );
        }
      }
    });
    this.getConsumables();
    this.getHeaderData();
  },
  methods: {
    getTransferDetailsById() {
      const payload = {
        trfHdrId: this.batchData.trf_batch_id
      };
      this.loader = true;
      this.$store
        .dispatch('assets/getTransferDetailsById', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data;
            this.batchStatus = result.batch_status_meaning;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    showHideExcelUpload(flag) {
      this.showExcelUploadModal = flag;
      if (!flag) {
        this.getTransferredAssets();
      }
    },
    fillBatchDetails(item) {
      this.batchNo = item.batch_num;
      this.invoiceNumber = item.invoice_number;
      this.legalEntity = {
        text: item.le_name,
        value: item.le_id
      };
      this.bookType = {
        text: item.book_type_code,
        value: item.book_type_code
      };
      this.locationFrom = {
        text: item.location_from,
        value: item.location_from_id
      };
      this.locationFromAddress = item.location_from_address;
      this.locationToAddress = item.location_to_address;
      this.locationTo = {
        text: item.location_to,
        value: item.location_to_id
      };
      this.batchStatus = item.batch_status;
      this.transferHdrId = item.trf_batch_id;
      this.address1 = item.address_1;
      this.address2 = item.address_2;
      this.city = item.city;
      this.pin = item.pin_code;
      this.country = item.country;
      this.state = item.state;
      this.transferType = item.transfer_type;
      this.ewayBillNo = item.waybill;
      this.pickupDate = item.pickup_date;
      this.pickupTime = item.pickup_time;
    },
    getTransferBatch() {
      if (
        this.category.value ||
        this.type.value ||
        this.status.value ||
        this.assetNo ||
        this.serialNo ||
        this.modelNo ||
        this.manufacturer ||
        this.poNumber ||
        this.description ||
        this.purchaseDate[0] ||
        this.purchaseDate[1] ||
        this.valueFrom ||
        this.valueTo ||
        this.assetItem
      ) {
        this.payload = {
          trfHdrId: this.transferHdrId,
          _page: this.batchCurrentPage - 1,
          _limit: this.batchPerPage,
          searchParams: {
            asset_category_id: this.category.value,
            asset_type_vset: this.type.value,
            book_type_code: this.bookType.value,
            description: this.description,
            le_id: this.legalEntity.value,
            manufacturer_name: this.manufacturer,
            model_no: this.modelNo,
            owned_leased: null,
            po_number: this.poNumber,
            purchase_date_from: commonHelper.formattedDate(
              this.purchaseDate[0]
            ),
            purchase_date_to: commonHelper.formattedDate(this.purchaseDate[1]),
            serial_no: this.serialNo,
            doc_num: this.assetNo,
            item_id: this.assetItem.value,
            processor: this.processorType.value,
            hr_location: this.locationFrom.value,
            fa_location: null,
            actual_cost_from: this.valueFrom
              ? parseFloat(this.valueFrom.replaceAll(',', ''))
              : null,
            actual_cost_to: this.valueFrom
              ? parseFloat(this.valueTo.replaceAll(',', ''))
              : null
          }
        };
        this.loader = true;
        this.$store
          .dispatch('assets/getAllAssetsForTransfer', this.payload)
          .then(response => {
            this.loader = false;
            if (response.status === 200) {
              const results = response.data.data.page;
              this.transferAssetFromData = results.map(data => {
                if(this.batchData.transfer_type =='RMA'){
                  data.checkBox = false;
                  data.damage_type={
                    damage:[]
                  };
                }else{
                  data.checkBox = false;
                }
                return data;
              });
              // this.transferAssetFromData=this.transferAssetFromData.map(item => !this.selectedTransferAssetFromData.some(obj => {if(obj.doc_num === item.doc_num){
              //   item.checkBox=true;
              // }}))
              this.transferAssetFromRows = response.data.data.total_elements;
              for (let i of this.selectedTransferAssetFromData) {
                for (let j of this.transferAssetFromData) {
                  if (i.tag_number === j.tag_number) {
                    j.checkBox = i.checkBox;
                  }
                }
              }
              if (
                this.selectedTransferAsset ===
                  this.selectedTransferAssetFromData.length &&
                this.selectedTransferAsset !== 0
              ) {
                this.allBatch = true;
              } else {
                this.allBatch = false;
              }
            }
          })
          .catch(() => (this.loader = false));
      } else {
        alert('Please fill at least one search filter ');
      }
    },
    getTransferredAssets() {
      const payload = {
        trfHdrId: this.transferHdrId,
        pagination: {
          _page: this.assetCurrentPage - 1,
          _limit: 200 //this.perPage
        }
      };
      this.loader = true;
      this.$store
        .dispatch('assets/getTransferredAssets', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            let results = response.data.data.page;
            this.assetNumber = results[0].oracle_id;
            // this.assetNumber = this.assetNumber.toString();
            if (results.length) {
              this.disableFlag = true;
            }
            results = results.map(asset => {
              if (typeof asset.damage_type === "string") {
                  asset.damage_type = JSON.parse(asset.damage_type);
              }
              return asset;
            });
            this.transferAssetToData = results.map(data => {
              data.checkBox = false;
              return data;
            });
            this.transferAssetToData = this.transferAssetToData.filter(el => {
              return el.rma_status !== 'Close';
            });
            this.getDepreciationValue();
            this.transferAssetToRows = response.data.data.total_elements;
            if (this.selectedReceiveAsset === 0) {
              this.allTransferredBatch = false;
            } else {
              this.allTransferredBatch = true;
            }
          }
        })
        .catch(() => (this.loader = false));
    },
    openValueSetModal(vsetCode, index, valuetype) {
      this.chooseParentVsetId(vsetCode);
      this.index = index;
      this.consumableIndex = index;
      this.valuetype = valuetype;
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      if (this.vsetCode === appStrings.VALUESETTYPE.FA_ASSET_ITEMS) {
        if (this.valuetype === 'CONSUMABLE') {
          this.parent_value_set_id = 'CONSUMABLE';
        } else if (this.valuetype === 'ASSET') {
          this.parent_value_set_id = 'ASSET';
        }
      }
      if (this.vsetCode === appStrings.VALUESETTYPE.FA_ITEM_PROCESSOR) {
        this.parent_value_set_id = this.itemId;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      switch (this.vsetCode) {
        case appStrings.VALUESETTYPE.ASSET_CATEGORY:
          this.category = {
            value: item.value_set_dtl_id,
            text: item.value_code
          };
          break;
        case appStrings.VALUESETTYPE.ASSET_TYPE:
          this.type = {
            value: item.value_code,
            text: item.value_meaning
          };
          break;
        case appStrings.VALUESETTYPE.ASSET_STATUS:
          this.status = {
            value: item.value_code,
            text: item.value_meaning
          };
          break;
        case appStrings.VALUESETTYPE.ASSET_OWNED_TYPE:
          this.owned = {
            value: item.value_code,
            text: item.value_meaning
          };
          break;
        case appStrings.VALUESETTYPE.TAX_CATEGORY_NAME_VSET:
          if (this.valuetype === 'CONSUMABLE') {
            if(this.consumableIndex > 0) {
              this.consumablesData[this.consumableIndex].tax_cat_id = item.value_set_dtl_id;
              this.consumablesData[this.consumableIndex].tax_cat_name = item.value_code;
              this.applyTaxCategoryOnConsumables(item.value_set_dtl_id);
            } else {
              this.defaultTaxCategoryConsumables = item.value_code;
              this.defaultTaxCategoryConsumablesId = item.value_set_dtl_id;
              this.setTaxCategoryInConsumables();
            }
          } else if (this.valuetype === 'CONSUMABLES_INPUT') {
            if (this.index >= 0) {
              this.consumablesData[this.index].tax_cat_name =
                item.category_name;
              this.consumablesData[this.index].tax_cat_id = item.tax_cat_hdr_id;
              this.index = null;
              this.applyTaxCategory(item.value_set_dtl_id);
            }
          } else {
            if (this.assetIndex >= 0) {
              this.transferAssetToData[this.assetIndex].tax_cat_name =
                item.value_code;
              this.transferAssetToData[this.assetIndex].tax_cat_hdr_id =
                item.value_set_dtl_id;
              this.assetIndex = null;
              this.applyTaxCategory(item.value_set_dtl_id);
            } else {
              this.defaultTaxCategory = item.value_code;
              this.defaultTaxCategoryId = item.value_set_dtl_id;
              this.setTaxCategoryInTrnsfrAsset();
            }
          }
          break;
        case appStrings.VALUESETTYPE.FA_ASSET_ITEMS:
          if (this.valuetype === 'CONSUMABLE') {
            this.addConsumablesData[this.index].item_type = item.value_code;
          } else if (this.valuetype === 'ASSET') {
            this.assetItem = {
              value: item.item_id,
              text: item.item_name
            };
            this.itemId = item.item_id;
          }
          break;
        case appStrings.VALUESETTYPE.FA_ITEM_PROCESSOR:
          this.processorType = {
            value: item.value_set_dtl_id,
            text: item.processor
          };
          break;
        case appStrings.VALUESETTYPE.ASSET_DAMAGE_TYPE:
          this.transferAssetFromData[this.index].damage_type.damage.push(item.value_code)
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    showHideAdvSearchModal(flag) {
      this.showAdvSearchModal = flag;
    },
    mainSearch() {},
    clear() {
      this.category = {
        text: null,
        value: null
      };
      this.invoice_num = null;
      this.type = {
        text: null,
        value: null
      };
      this.valueFrom = null;
      this.valueTo = null;
      this.assetNo = '';
      this.serialNo = '';
      this.modelNo = '';
      this.purchaseDate = [];
      this.status = {
        text: null,
        value: null
      };
      this.poNumber = '';
      this.manufacturer = '';
      this.description = '';
      this.value = '';
      this.limit = null;
      this.searchAssetData = [];
      // this.transferAssetToData = [];
      // this.transferAssetFromData = [];
    },
    chooseValueset(name, index) {
      this.assetIndex = index;
      switch (name) {
        case 'category':
          this.openValueSetModal(appStrings.VALUESETTYPE.ASSET_CATEGORY);
          break;
        case 'type':
          this.openValueSetModal(appStrings.VALUESETTYPE.ASSET_TYPE);
          break;
        case 'status':
          this.openValueSetModal(appStrings.VALUESETTYPE.ASSET_STATUS);
          break;
        case 'owned':
          this.openValueSetModal(appStrings.VALUESETTYPE.ASSET_OWNED_TYPE);
          break;
        case 'tax_category':
          this.openValueSetModal(
            appStrings.VALUESETTYPE.TAX_CATEGORY_NAME_VSET
          );
      }
    },
    chooseParentVsetId(name) {
      switch (name) {
        case appStrings.VALUESETTYPE.ASSET_BOOK_TYPE:
          this.parent_value_set_id = this.legalEntity.value;
          break;
        case appStrings.VALUESETTYPE.ASSET_CATEGORY:
          this.parent_value_set_id = this.bookType.value;
          break;
        case appStrings.VALUESETTYPE.TAX_CATEGORY_NAME_VSET:
          this.parent_value_set_id = this.defaultTaxGroupId;
          this.taxType = 'IGST';
          break;
        default:
          this.parent_value_set_id = null;
      }
    },
    checkAllBatch(event) {
      this.allBatch = event;
      this.transferAssetFromData = this.transferAssetFromData.map(data => {
        data.checkBox = event;
        return data;
      });
      if (event) {
        this.selectedTransferAssetFromData = [];
        this.selectedTransferAssetFromData = this.transferAssetFromData;
      } else {
        this.selectedTransferAssetFromData = [];
      }
    },
    checkBatchRow(event, row, index, asstNum) {
      this.transferAssetFromData[index].checkBox = event;

      if (event) {
        this.selectedTransferAssetFromData.push(row);
        // row.checkBox = event;
      } else {
        // this.selectedTransferAssetFromData.splice(index, 1);
        // row.checkBox = event;
        this.selectedTransferAssetFromData = this.selectedTransferAssetFromData.filter(
          el => el.doc_num !== asstNum
        );
      }
    },
    checkAllTransferredBatch(event) {
      this.allTransferredBatch = event;
      this.transferAssetToData = this.transferAssetToData.map(data => {
        data.checkBox = event;
        return data;
      });
    },
    checkTransferredBatchRow(event, row) {
      row.checkBox = event;
    },
    validateHsnCode(item) {
      if (item.hsn_sac_code.length !== 6 && item.hsn_sac_code.length !== 8) {
        item.hsn_sac_code = null;
        alert('Please Enter HSN Code 6 or 8 digits!');
      }
    },
    setDefaultBatchParam() {
      this.transferAssetFromData.forEach(data => {
        if (data.checkBox) {
          data.awb_no = this.defaultAwbNo;
          data.remark = this.defaultRemarks;
          if (
            this.defaultHsnCode.length !== 6 &&
            this.defaultHsnCode.length !== 8
          ) {
            this.defaultHsnCode = null;
            this.transferAssetFromData.forEach(elem => {
              elem.hsn_sac_code = null;
            });
            return alert('Please enter HSN Code between 6 or 8 digits!');
          } else {
            data.hsn_sac_code = this.defaultHsnCode;
          }
        }
      });
      this.transferAssetFromData = JSON.parse(
        JSON.stringify(this.transferAssetFromData)
      );
    },
    setDefaultTaxGroup() {
      const taxGrp = commonHelper.getValuesetData(
        appStrings.VALUESETTYPE.TAX_GROUP_NAME_VSET
      );
      taxGrp.then(key => {
        this.defaultTaxGroup = key[0].value_code;
        this.defaultTaxGroupId = key[0].value_set_dtl_id;
        this.defaultTaxGroupConsumables = key[0].value_code;
        this.defaultTaxGroupConsumablesId = key[0].value_set_dtl_id;
      });
    },
    addTransferAssetsDetails() {
      // const filterAssets = this.transferAssetFromData.filter(
      //   key => key.checkBox
      // );
      const hsnAvilable = this.selectedTransferAssetFromData.find(
        key =>
          key.hsn_sac_code === null ||
          key.hsn_sac_code === '' ||
          key.hsn_sac_code.length < 6 ||
          key.hsn_sac_code.length > 8
      );
      if (hsnAvilable) {
        return alert(
          `Tag Number: ${hsnAvilable.doc_num}, HSN Code: ${hsnAvilable.hsn_sac_code} is invalid!`
        );
      }
      if (this.selectedTransferAssetFromData.length > 0) {
        const details = this.selectedTransferAssetFromData.map(data => {
          if(this.batchData.transfer_type =='RMA'){
            return {
              asset_id: data.asset_id,
              awb_no: data.awb_no,
              description: data.description,
              hsn_sac_code: data.hsn_sac_code,
              damage_type: {
                damage_type_dtl:data.damage_type.damage
              }
            };
          }else{
            return {
              asset_id: data.asset_id,
              awb_no: data.awb_no,
              description: data.description,
              hsn_sac_code: data.hsn_sac_code,
            };
          }
        });
        const payload = {
          trfHdrId: this.transferHdrId,
          assetDetails: {
            asset_details: details
          }
        };
        if(this.batchData.transfer_type =='RMA'){
          var damageCount=0;
          payload.assetDetails.asset_details.map(ele=>{
            if(ele.damage_type.damage_type_dtl.length==0){
              damageCount++
            }
          })
          if(damageCount>0){
            this.makeToast('Kindly select mandatory fields','danger')
            damageCount=0;
          }else{
            this.loader = true;
            this.$store
              .dispatch('assets/addTransferAssetsDetails', payload)
              .then(response => {
                this.loader = false;
                if (response.status === 200) {
                  const results = response.data.message;
                  this.makeToast(results, 'success');
                  this.disableFlag = true;
                  this.getTransferredAssets();
                  this.getTransferBatch();
                  this.selectedTransferAssetFromData = [];
                }
              })
              .catch(() => (this.loader = false));
              damageCount=0;
          }
        }else{
          this.loader = true;
          this.$store
            .dispatch('assets/addTransferAssetsDetails', payload)
            .then(response => {
              this.loader = false;
              if (response.status === 200) {
                const results = response.data.message;
                this.makeToast(results, 'success');
                this.disableFlag = true;
                this.getTransferredAssets();
                this.getTransferBatch();
                this.selectedTransferAssetFromData = [];
              }
            })
            .catch(() => (this.loader = false));
        }
      } else {
        this.responseMsg = 'Please select assets to transfer';
        this.makeToast(this.responseMsg, 'warning');
      }
    },
    deleteTransferAssetDetails() {
      const filterAssets = this.transferAssetToData.filter(
        elem => elem.checkBox
      );
      if (filterAssets.length > 0) {
        const details = filterAssets.map(data => {
          return {
            trf_dtl_id: data.trf_dtl_id
          };
        });
        const payload = {
          trfHdrId: this.transferHdrId,
          assetDetails: details
        };
        this.loader = true;
        this.$store
          .dispatch('assets/deleteTransferAssetsDetails', payload)
          .then(response => {
            this.loader = false;
            if (response.status === 200) {
              const results = response.data.data.page;
              this.disableFlag = false;
              this.allTransferredBatch=false;
              this.transferAssetToData = results.map(data => {
                data.checkBox = false;
                return data;
              });
              this.transferAssetToRows = response.data.data.total_elements;
              // this.transferAssetFromData = [];
              this.getTransferBatch();
              this.getTransferredAssets();
            } else {
              this.responseMsg = response.response.data.message;
              this.makeToast(this.responseMsg, 'warning');
            }
          })
          .catch(() => (this.loader = false));
      } else {
        this.responseMsg = 'Please select assets to delete';
        this.makeToast(this.responseMsg, 'warning');
      }
    },
    saveTaxDetails() {
      this.loader = true;
      const filteredDetails = this.transferAssetToData.filter(
        elem => elem.checkBox && elem.tax_cat_hdr_id
      );
      const details = filteredDetails.map(elem => {
        return {
          exchange_rate: 1,
          fp_gstn: this.fromGstn,
          hsn: elem.hsn_sac_code,
          location_id: this.locationFrom.value,
          module_id: this.moduleId,
          sac: '',
          tax_cat_id: elem.tax_cat_hdr_id,
          tp_gstn: this.toGstn,
          trx_amount: parseFloat(elem.gross_book_value.replaceAll(',', '')),
          trx_cat: 'ASSET_TRANSFER',
          trx_id: elem.trf_hdr_id,
          trx_line_id: elem.trf_dtl_id
        };
      });
      const payload = {
        save_tax_details: details
      };
      this.$store
        .dispatch('assets/saveAssetTaxCategory', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.calculateAssetTax();
          }
        })
        .catch(err => {
          this.loader = false;
          alert(err.message);
        });
    },
    getGstnFromLocation(locationId, locType) {
      const payload = {
        locId: locationId,
        leId: this.legalEntity.value
      };
      this.loader = true;
      this.$store
        .dispatch('assets/getLocationBasedGstn', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            const result = response.data.data;
            if (locType === 'from_loc') {
              this.fromGstn = result;
            } else if (locType === 'to_loc') {
              this.toGstn = result;
            }
            this.checkTaxAmountApplicable();
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    applyTaxCategory(taxCatHdrId) {
      this.loader = true;
      const filteredDetails = this.transferAssetToData.filter(
        elem => elem.checkBox && elem.tax_cat_hdr_id
      );
      const details = filteredDetails.map(data => {
        return data.trf_dtl_id;
      });
      const payload = {
        trfHdrId: this.transferHdrId,
        assetDetails: {
          tax_cat_hdr_id: taxCatHdrId,
          trf_dtl_ids: details
        }
      };
      this.$store
        .dispatch('assets/saveAssetTaxCatByTrnsfrBatchId', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.saveTaxDetails();
          }
        })
        .catch(err => {
          this.loader = false;
          alert(err.message);
        });
    },
    setTaxCategoryInTrnsfrAsset() {
      const filteredDetails = this.transferAssetToData.filter(
        elem => elem.checkBox
      );
      filteredDetails.forEach(data => {
        (data.tax_cat_name = this.defaultTaxCategory),
          (data.tax_cat_hdr_id = this.defaultTaxCategoryId);
      });
      this.applyTaxCategory(this.defaultTaxCategoryId);
    },
    setTaxCategoryInConsumables() {
      this.allConsumables = false;
      const filteredDetails = this.consumablesData.filter(
        elem => elem.selectBox
      );
      filteredDetails.forEach(data => {
        (data.tax_cat_name = this.defaultTaxCategoryConsumables),
          (data.tax_cat_id = this.defaultTaxCategoryConsumablesId);
      });
      this.applyTaxCategoryOnConsumables(this.defaultTaxCategoryConsumablesId);
    },
    calculateAssetTax() {
      this.loader = true;
      const filteredDetails = this.transferAssetToData.filter(
        elem => elem.checkBox && elem.tax_cat_hdr_id
      );
      const details = filteredDetails.map(data => {
        return {
          trx_cat: 'ASSET_TRANSFER',
          trx_id: data.trf_hdr_id,
          trx_line_id: data.trf_dtl_id
        };
      });
      const payload = {
        tax_calculate_details: details
      };
      this.$store
        .dispatch('assets/calculateAssetTaxValue', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.getTransferredAssets();
          }
        })
        .catch(err => {
          this.loader = false;
          alert(err.message);
        });
    },
    checkTaxApplicable() {
      if (this.fromGstn && this.toGstn) {
        const fpGstnCode = this.fromGstn.slice(0, 2);
        const tpGstnCode = this.toGstn.slice(0, 2);
        return fpGstnCode === tpGstnCode ? false : true;
      }
      return false;
    },
    processTransferredAssets() {
      if (this.transferType === 'RMA') {
        const taxValidate = this.checkTaxApplicable();

        if (taxValidate) {
          const checkTaxApplied = this.transferAssetToData.every(
            key => key.tax_cat_hdr_id
          );

          if (!checkTaxApplied) {
            return alert('Please apply tax category before processing batch!');
          }
        }
        const payload = {
          trx_id: this.transferHdrId,
          trx_cat: 'ASSET_TRANSFER',
          // awb_flag: this.active,
          // irn:this.irnNumber,
        };
        this.loader = true;
        this.$store
          .dispatch('assets/processTransferRma', payload)
          .then(response => {
            this.loader = false;
            if (response.status === 200) {
              this.responseMsg = response.data.message;
              this.disableFlag = true;
              this.ewayBillNo = response.data.data.waybill;
              this.makeToast(this.responseMsg, 'success');
              this.invoiceNumber = response.data.data.invoice_number;
              this.batchStatus = response.data.data.batch_status;
              this.getTransferredAssets();
              this.getIrnProcessById(this.batchData.trf_batch_id);
              // this.getProcessStatusAsset(this.transferHdrId);
              this.getProcessTransfer();
              this.$emit('updateTransferAssetList');
            } else {
              this.responseMsg = response.response.data.message;
              this.makeToast(this.responseMsg, 'warning');
            }
          })
          .catch(err => {
            this.loader = false;
            this.makeToast(err.message, 'danger');
          });
      } else {
        const taxValidate = this.checkTaxApplicable();

        if (taxValidate) {
          const checkTaxApplied = this.transferAssetToData.every(
            key => key.tax_cat_hdr_id
          );

          if (!checkTaxApplied) {
            return alert('Please apply tax category before processing batch!');
          }
        }
        const payload = {
          trx_id: this.transferHdrId,
          trx_cat: 'ASSET_TRANSFER',
          // awb_flag: this.active,
          // irn:this.irnNumber
        };
        this.loader = true;
        this.$store
          .dispatch('assets/processTransferAssets', payload)
          .then(response => {
            this.loader = false;
            if (response.status === 200) {
              this.responseMsg = response.data.message;
              this.disableFlag = true;
              this.ewayBillNo = response.data.data.waybill;
              this.makeToast(this.responseMsg, 'success');
              this.invoiceNumber = response.data.data.invoice_number;
              this.batchStatus = response.data.data.batch_status;
              this.getTransferredAssets();
              this.getIrnProcessById(this.batchData.trf_batch_id);
              // this.getProcessStatusAsset(this.transferHdrId);
              this.getProcessTransfer();
              this.$emit('updateTransferAssetList');
            } else {
              this.responseMsg = response.response.data.message;
              this.makeToast(this.responseMsg, 'warning');
            }
          })
          .catch(err => {
            this.loader = false;
            this.makeToast(err.message, 'danger');
          });
      }
    },

    checkMinLength(e) {
      const inputValue = e.target.value;
      if (inputValue.length < 6 || inputValue.length > 8) {
        alert('Please enter HSN Code between 6 to 8 digits!');
        return (this.hsnValid = false);
      }
      return (this.hsnValid = true);
    },
    clearVsetValues(vsetCode, index) {
      if (vsetCode === 'category') {
        this.category = {
          value: null,
          text: null
        };
      } else if (vsetCode === 'type') {
        this.type = {
          value: null,
          text: null
        };
      } else if (vsetCode === 'status') {
        this.status = {
          value: null,
          text: null
        };
      } else if (vsetCode === 'tax_category') {
        if (index >= 0) {
          this.transferAssetToData[index].tax_cat_name = null;
          this.transferAssetToData[index].tax_cat_hdr_id = null;
        } else this.defaultTaxCategory = null;
        this.defaultTaxCategoryId = null;
      } else if (vsetCode === 'assetItem') {
        this.assetItem = {
          value: null,
          text: null
        };
      }
    },
    // getProcessStatusAsset(trfHdrId) {
    //   this.loader = true;
    //   this.$store
    //     .dispatch('assets/getProcessStatusAsset', trfHdrId)
    //     .then(resp => {
    //       this.loader = false;
    //       if (resp.status === 200) {
    //         this.requestNumId = resp.data.data.request_num;
    //         this.getSubmitRequest();
    //       }
    //     })
    //     .catch(() => {
    //       this.loader = false;
    //     });
    // },
    // getProcessStatusById() {
    //   this.getProcessStatus(this.transferHdrId);
    // },
    // getProcessStatus(trfHdrId) {
    //   this.loader = true;
    //   this.$store
    //     .dispatch('assets/getProcessStatus', trfHdrId)
    //     .then(resp => {
    //       this.loader = false;
    //       if (resp.status === 200) {
    //         this.requestStatus = resp.data.data.status;
    //       }
    //     })
    //     .catch(() => {
    //       this.loader = false;
    //     });
    // },
    getPrintTransferAsset(flag) {
      this.isPrintInvoice = flag;
      this.loader = true;
      this.$store
        .dispatch('assets/getPrintTransferAsset')
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.printInvoiceNumberFlag = true;
            this.requestId = resp.data.data.request_id;
            this.templateId = resp.data.data.template_id;
            this.saveSubmitRequest();
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    saveSubmitRequest() {
      const payload = {
        admin_submit_req_details: [
          {
            request_num: 0,
            request_id: this.requestId,
            template_id: this.templateId,
            schedule_type: 'asap', //pass hardcode
            request_start_date: null,
            request_end_date: null,
            resubmit_interval: null,
            resubmit_interval_unit: null,
            day_of_month: null,
            day_of_week: null,
            output_format_id: 'PDF', //pass hardcode
            no_of_args: 25,
            request_parameter: this.batchData.trf_batch_id,
            sms_flag: false,
            email_flag: false,
            whatsapp_flag: false,
            child_req_count: null,
            email_comm_template_id: null,
            sms_comm_template_id: null,
            whatsapp_comm_template_id: null,
            argument1: this.batchData.trf_batch_id,
            argument2: null,
            argument3: null,
            argument4: null,
            argument5: null,
            argument6: null,
            argument7: null,
            argument8: null,
            argument9: null,
            argument10: null,
            argument11: null,
            argument12: null,
            argument13: null,
            argument14: null,
            argument15: null,
            argument16: null,
            argument17: null,
            argument18: null,
            argument19: null,
            argument20: null,
            argument21: null,
            argument22: null,
            argument23: null,
            argument24: null,
            argument25: null
          }
        ]
      };
      this.loader = true;
      this.$store
        .dispatch('template/saveSubmitRequest', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 201) {
            this.requestNumId = resp.data.data[0].id;
            this.getSubmitRequest();
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getSubmitRequest() {
      this.loader = true;
      this.$store
        .dispatch('template/getSubmitRequest', this.requestNumId)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.requestStatus = resp.data.data[0].status;
            this.reqId = resp.data.data[0].id;
          }
          this.getIrnProcessById(this.batchData.trf_batch_id);
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getIrnProcessById(trfHdrId) {
      this.loader = true;
      this.$store
        .dispatch('assets/getIrnProcessById', trfHdrId)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.irnNumber = resp.data.data.irn;
            this.irnError = resp.data.data.error_msg;
            this.irnStatus = resp.data.data.status;
            this.getTransferDetailsById();
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getIrnRequest() {
      const payload = {
        trfHdrId: this.batchData.trf_batch_id
      };
      this.loader = true;
      this.$store
        .dispatch('assets/getIrnRequest',payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.irnRequestId = resp.data.data.request_id;
            this.irnTemplateId = resp.data.data.template_id;
            // this.irnSaveSubmitRequest();
            // this.getSubmitRequest();
            this.getIrnProcessById(this.batchData.trf_batch_id);

          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    // irnSaveSubmitRequest() {
    //   const payload = {
    //     admin_submit_req_details: [
    //       {
    //         request_num: 0,
    //         request_id: this.irnRequestId,
    //         template_id: this.irnTemplateId,
    //         schedule_type: 'asap', //pass hardcode
    //         request_start_date: null,
    //         request_end_date: null,
    //         resubmit_interval: null,
    //         resubmit_interval_unit: null,
    //         day_of_month: null,
    //         day_of_week: null,
    //         output_format_id: 'SBLANK', //pass hardcode
    //         no_of_args: 25,
    //         request_parameter: 'GENERATEIRN,FA,' + this.batchData.trf_batch_id,
    //         sms_flag: false,
    //         email_flag: false,
    //         whatsapp_flag: false,
    //         child_req_count: null,
    //         email_comm_template_id: null,
    //         sms_comm_template_id: null,
    //         whatsapp_comm_template_id: null,
    //         argument1: 'GENERATEIRN',
    //         argument2: 'FA',
    //         argument3: this.batchData.trf_batch_id,
    //         argument4: null,
    //         argument5: null,
    //         argument6: null,
    //         argument7: null,
    //         argument8: null,
    //         argument9: null,
    //         argument10: null,
    //         argument11: null,
    //         argument12: null,
    //         argument13: null,
    //         argument14: null,
    //         argument15: null,
    //         argument16: null,
    //         argument17: null,
    //         argument18: null,
    //         argument19: null,
    //         argument20: null,
    //         argument21: null,
    //         argument22: null,
    //         argument23: null,
    //         argument24: null,
    //         argument25: null
    //       }
    //     ]
    //   };
    //   this.loader = true;
    //   this.$store
    //     .dispatch('template/irnSaveSubmitRequest', payload)
    //     .then(resp => {
    //       this.loader = false;
    //       if (resp.status === 201) {
    //         this.requestNumId = resp.data.data[0].id;
    //         this.getSubmitRequest();
    //         this.getTransferDetailsById();
    //       }
    //     })
    //     .catch(() => {
    //       this.loader = false;
    //     });
    // },
    // getIrnSubmitRequest() {
    //   this.loader = true;
    //   this.$store
    //     .dispatch('template/getSubmitRequest', this.irnRequestNumId)
    //     .then(resp => {
    //       this.loader = false;
    //       if (resp.status === 200) {
    //         this.irnRequestStatus = resp.data.data[0].status;
    //         this.irnReqId = resp.data.data[0].id;
    //       }
    //     })
    //     .catch(() => {
    //       this.loader = false;
    //     });
    // },
    updateHsnCodeById() {
      if (this.existingHsnCode !== '' && this.updatedHsnCode !== '') {
        if (
          (this.existingHsnCode.toString().length !== 6 &&
            this.existingHsnCode.toString().length !== 8) ||
          (this.updatedHsnCode.toString().length !== 6 &&
            this.updatedHsnCode.toString().length !== 8)
        ) {
          this.makeToast(
            'HSN code can be either 6 digits or 8 digits only!',
            'warning'
          );
        } else {
          const payload = {
            existing_hsn_code: this.existingHsnCode,
            new_hsn_code: this.updatedHsnCode,
            trf_hdr_id: this.batchData.trf_batch_id
          };
          this.loader = true;
          this.$store
            .dispatch('assets/updateHsnCodeById', payload)
            .then(response => {
              this.loader = false;
              if (response.status === 200) {
                this.responseMsg = response.data.message;
                this.makeToast(this.responseMsg, 'success');
                this.getTransferredAssets();
              } else {
                this.responseMsg = response.response.data.message;
                this.makeToast(this.responseMsg, 'warning');
              }
            })
            .catch(() => {
              this.loader = false;
              this.responseMsg = appStrings.autoFailedMsg;
              this.makeToast(this.responseMsg, 'danger');
            });
        }
      } else {
        this.makeToast('Please fill in the mandatory fields!', 'warning');
      }
    },
    getProcessTransfer() {
      const payload = {
        trfHdrId: this.batchData.trf_batch_id
      };
      this.loader = true;
      this.$store
        .dispatch('assets/getProcessTransfer', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.refreshBtn = true;
            this.requestStatus = resp.data.data[0].status;
            this.getIrnProcessById(this.batchData.trf_batch_id);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addNewRow() {
      this.addConsumablesData.push({
        item_type: '',
        item_code: '',
        hsn_sac_code: '',
        item_description: '',
        quantity: '',
        unit_price: '',
        amount: '',
        tax: {
          value: '15',
          text: 'IGST 18%'
        }
      });
    },
    removeRow(index, type) {
      if (type === 'consumablesData') {
        this.consumablesData.splice(index, 1);
      } else {
        this.addConsumablesData.splice(index, 1);
      }
    },
    calculateAmount(quantity, unit_price, index) {
      let amount = quantity * unit_price;
      this.addConsumablesData[index].amount = amount;
    },
    addConsumables() {
      let consumablesdata = [];
      for (let i of this.addConsumablesData) {
        consumablesdata.push({
          hsn_sac_code: i.hsn_sac_code,
          item_code: i.item_code,
          item_type: i.item_type,
          item_description: i.item_description,
          quantity: i.quantity,
          unit_price: i.unit_price,
          tax_cat_id: i.tax.value,
          amount: i.amount
        });
      }
      const payload = {
        trfHdrId: this.batchData.trf_batch_id,
        asset_consumable_details: consumablesdata
      };
      this.loader = true;
      this.$store
        .dispatch('assets/postConsumablesDetails', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.responseMsg = resp.data.message;
            this.makeToast(this.responseMsg, 'success');
            this.getConsumables();
          } else {
            this.responseMsg = resp.response.data.message;
            this.makeToast(this.responseMsg, 'warning');
          }
        })
        .catch(err => {
          this.loader = false;
          alert(err.message);
        });
    },
    getConsumables() {
      const payload = {
        trfHdrId: this.batchData.trf_batch_id
      };
      this.loader = true;
      this.$store
        .dispatch('assets/getConsumablesDetails', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.consumablesData = resp.data.data.page;
          }
        })
        .catch(err => {
          this.loader = false;
          alert(err.message);
        });
    },
    checkConsumableRow(event, row) {
      row.selectBox = event;
    },
    checkAllConsumablesRow(event) {
      this.allConsumables = event;
      this.consumablesData = this.consumablesData.map(data => {
        data.selectBox = event;
        return data;
      });
    },
    makeToast(content, variant) {
      this.$bvToast.toast(content, {
        title: 'Alert',
        variant: variant,
        solid: true
      });
    },
    printLabel() {
      const payload = {
        waybill: this.ewayBillNo
      };
      this.loader = true;
      this.$store
        .dispatch('assets/printLabel', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.pdfDownloadingLink =
              resp.data.data.packages[0].pdf_download_link;
            window.open(this.pdfDownloadingLink, '_label');
          }
        })
        .catch(err => {
          this.loader = false;
          alert(err.message);
        });
    },
    viewMoreOrLess() {
      this.viewMoreFlag = !this.viewMoreFlag;
    },
    createShipment() {
      this.showOpenGenerateAwbModal = true;
    },
    generateAwb() {
      if(!this.name || !this.phone || (this.active && !this.awbNum)){
        this.$bvToast.toast('Kindly fill all the mandatory fields', {
          title: 'Alert',
          variant: 'danger',
          solid: true
        });
      }else{
        if (!this.nameFlag && !this.numFlag) {
          const payload = {
            trfHdrId: this.transferHdrId,
            name: this.name,
            phone: this.phone,
            wayBillNo:this.awbNum
          };
          this.loader = true;
          this.$store
            .dispatch('assets/createShipment', payload)
            .then(resp => {
              this.loader = false;
              if (resp.status === 200) {
                this.ewayBillNo = resp.data.data.wayBillNo;
                this.username = resp.data.data.name;
                this.userphone = resp.data.data.phone;
                this.responseMsg = resp.data.message;
                this.makeToast(this.responseMsg, 'success');
                this.getHeaderData()
              }
              else{
                this.makeToast(resp.response.data.message, 'danger');
              }
            })
            .catch(err => {
              this.loader = false;
              this.makeToast(err.message, 'danger');
            });
          this.showOpenGenerateAwbModal = false;
        } else {
          this.$bvToast.toast('Kindly validate the details filled', {
            title: 'Alert',
            variant: 'danger',
            solid: true
          });
        }
      }
    },
    generateEwayBill() {
      this.showOpenEwayBillModal = true;
    },
    showHideEwayBillModal(flag) {
      this.showOpenEwayBillModal = flag;
    },
    showHideShipmentTrackingModal(flag) {
      this.showOpenShipmentTrackingModal = flag;
    },
    showHideGenerateAwbModal(flag) {
      this.name=null,
      this.phone=null,
      this.nameFlag=false,
      this.numFlag=false,
      this.showOpenGenerateAwbModal = flag;
    },
    showHidePickupRequestModal(flag) {
      this.showOpenPickupRequestModal = flag;
    },
    getDepreciationValue() {
      let assetsDepreciated = [];
      for (let i of this.transferAssetToData) {
        assetsDepreciated.push({
          asset_no: i.oracle_id
        });
      }
      const payload = {
        _page: this.assetCurrentPage - 1,
        _limit: this.assetPerPage,
        asset_id_dtl: assetsDepreciated
      };
      this.loader = true;
      this.$store
        .dispatch('assets/assetDepreciation', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            let response = resp.data.data.data.page;
            this.responseMsg = resp.data.message;
            this.makeToast(this.responseMsg, 'success');
            for (
              let i = 0, j = 0;
              i < this.transferAssetToData.length, j < response.length;
              i++, j++
            ) {
              this.transferAssetToData[i].current_cost =
                response[j].current_cost;
            }
            setTimeout(() => {
              this.transferAssetToData = JSON.parse(
                JSON.stringify(this.transferAssetToData)
              );
            }, 0);
          }
        })
        .catch(err => {
          this.loader = false;
          this.makeToast(err.message, 'danger');
        });
    },
    generatePickRequest() {
      this.showOpenPickupRequestModal = true;
    },
    submitPickRequest() {
      const payload = {
        trfHdrId: this.transferHdrId,
        pickup_time: this.selectedPickupTime
      };
      this.loader = true;
      this.$store
        .dispatch('assets/createPickRequest', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.showOpenPickupRequestModal = false;
            this.responseMsg = resp.data.message;
            this.makeToast(this.responseMsg, 'success');
            this.pickupDate = resp.data.pickup_date;
            this.pickupTime = resp.data.pickup_time;
          } else {
            this.responseMsg = resp.response.data.message;
            this.makeToast(this.responseMsg, 'warning');
          }
        })
        .catch(err => {
          this.loader = false;
          this.makeToast(err.message, 'danger');
        });
    },
    openShipmentTrackingModal() {
      this.shipmentTrackingData = [];
      const payload = {
        wayBillNo: this.ewayBillNo
      };
      this.loading = true;
      this.$store
        .dispatch('assets/getShipmentTrackingDetails', payload)
        .then(resp => {
          this.loading = false;
          if (resp.status === 200) {
            let result = resp.data.data.ShipmentData[0].Shipment.Scans;
            for (let i of result) {
              this.shipmentTrackingData.push(i.ScanDetail);
            }
            for (let i of this.shipmentTrackingData) {
              const scandate = this.convertISOToDDMMMYYYY(i.ScanDateTime);
              const myArray = i.ScanDateTime.split('T');
              i.scanDate = scandate;
              i.scanTime = myArray[1];
              i.scanTime = i.scanTime.substring(0, 8);
              const statusdate = this.convertISOToDDMMMYYYY(i.StatusDateTime);
              const myArray1 = i.StatusDateTime.split('T');
              i.StatusDate = statusdate;
              i.StatusTime = myArray1[1];
              i.StatusTime = i.StatusTime.substring(0, 8);
            }
          }
        })
        .catch(err => {
          this.loading = false;
          this.makeToast(err.message, 'danger');
        });
      this.showOpenShipmentTrackingModal = true;
    },
    convertISOToDDMMMYYYY(timestamp) {
      var dt = new Date(timestamp);
      var monthNames = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec'
      ];
      var day = dt.getDate();
      var month = monthNames[dt.getMonth()];
      var year = dt.getFullYear();
      var formattedDate = day + '/' + month + '/' + year;
      return formattedDate;
    },
    checkTaxAmountApplicable() {
      if (this.fromGstn && this.toGstn) {
        const fpGstnCode = this.fromGstn.slice(0, 2);
        const tpGstnCode = this.toGstn.slice(0, 2);
        if (fpGstnCode === tpGstnCode) {
          this.transferAssetToFields = [
            {
              key: 'checkBox',
              stickyColumn: true,
              class: this.batchStatus =='Incomplete'? '' : 'd-none'
            },
            {
              key: 'doc_num',
              label: this.batchType === 'IT' ? 'Asset Number' : 'Tag Number'
            },
            {
              key: 'description'
            },
            {
              key: 'gross_book_value',
              label: 'Value'
            },
            {
              key: 'current_cost'
            },
            {
              key: 'hsn_sac_code',
              label: 'HSN/SAC Code'
            },
            {
              key: 'awb_no',
              label: 'AWB No.'
            },
            {
              key:'serial_no',
              label:'Serial Number'
            },
            {
              key: 'category_name',
              label: 'Asset Category'
            },
            {
              key: 'oracle_id',
              label: 'Asset Id'
            },
            {
              key: 'transfer_description'
            },
            {
              key: 'damage_type',
              class: this.batchData.transfer_type =='RMA' && this.batchType=="IT" ? '' : 'd-none'
            },
            {
              key: 'post_to_oracle'
            },
            {
              key: 'error_msg'
            }
          ];
        }
      }
    },
    getCurrentDateTime() {
      const now = new Date();
      return now.toISOString().slice(0, 16);
    },
    rejectBatch() {
      const payload = {
        trf_hdr_id: this.transferHdrId
      };
      this.loader = true;
      this.$store
        .dispatch('assets/rejectBatch', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 201) {
            this.batchStatus = 'Rejected';
            // this.$emit('updateTransferAssetList');
            // this.fillBatchDetails();
            // this.responseMsg = resp.data.message;
            // this.makeToast(this.responseMsg, 'success');
          }
          else {
            this.responseMsg = resp.response.data.message;
            this.makeToast(this.responseMsg, 'warning');
          }
        })
        .catch(err => {
          this.loader = false;
          this.makeToast(err.message, 'danger');
        });
    },
    getHeaderData() {
      const payload = {
        trf_hdr_id: this.transferHdrId
      };
      this.loader = true;
      this.$store
        .dispatch('assets/getOutboundHeader', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            // if(resp.data.data.from_gstn_no.substring(0,2)==resp.data.data.to_gstn_no.substring(0,2)){
            //   this.awbIrnflag=true
            // }     
            this.ewayBillNo = resp.data.data.waybill;
            this.username = resp.data.data.name;
            this.userphone = resp.data.data.phone;
            this.pickupDate = resp.data.data.pickup_date;
            this.pickupTime = resp.data.data.pickup_time;
            this.active= resp.data.data.awb_irn_bypass;
          }
        })
        .catch(err => {
          this.loader = false;
          this.makeToast(err.message, 'danger');
        });
    },
    applyTaxCategoryOnConsumables(taxCatHdrId) {
      this.loader = true;
      const filteredDetails = this.consumablesData.filter(
        elem => elem.selectBox
      );
      const details = filteredDetails.map(data => {
        return data.trf_cons_id;
      });
      const payload = {
        trfHdrId: this.transferHdrId,
        consumableDetails: {
          tax_cat_hdr_id: taxCatHdrId,
          trf_cons_ids: details
        }
      };
      this.$store
        .dispatch('assets/saveConsumablesTaxCatByTrnsfrBatchId', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.saveTaxDetailsForConsumables();
          }
        })
        .catch(err => {
          this.loader = false;
          alert(err.message);
        });
    },
    saveTaxDetailsForConsumables() {
      this.loader = true;
      const filteredDetails = this.consumablesData.filter(
        elem => elem.selectBox
      );
      const details = filteredDetails.map(elem => {
        return {
          exchange_rate: 1,
          fp_gstn: this.fromGstn,
          hsn: elem.hsn_sac_code,
          location_id: this.locationFrom.value,
          module_id: this.moduleId,
          sac: '',
          tax_cat_id: elem.tax_cat_id,
          tp_gstn: this.toGstn,
          trx_amount: parseFloat(elem.AMOUNT.replaceAll(',', '')),
          trx_cat: 'CONSUMABLE_TRANSFER',
          trx_id: elem.trf_hdr_id,
          trx_line_id: elem.trf_cons_id
        };
      });
      const payload = {
        save_tax_details: details
      };
      this.$store
        .dispatch('assets/saveAssetTaxCategory', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.calculateConsumableTax();
          }
        })
        .catch(err => {
          this.loader = false;
          alert(err.message);
        });
    },
    calculateConsumableTax() {
      this.loader = true;
      const filteredDetails = this.consumablesData.filter(
        elem => elem.selectBox
      );
      const details = filteredDetails.map(data => {
        return {
          trx_cat: 'CONSUMABLE_TRANSFER',
          trx_id: data.trf_hdr_id,
          trx_line_id: data.trf_cons_id
        };
      });
      const payload = {
        tax_calculate_details: details
      };
      this.$store
        .dispatch('assets/calculateAssetTaxValue', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.getConsumables();
          }
        })
        .catch(err => {
          this.loader = false;
          alert(err.message);
        });
    },
    checkNameNum(data){
      if(data=='name'){
        this.name = this.name.replace(/^\s+|\s+$/g, '');
        if(this.regexPattern.test(this.name)){
          this.nameFlag = false;
         }else{
          this.nameFlag=true
         }
      }else{
        if(this.regexPattern1.test(this.phone)){
          this.numFlag = false;
         }else{
          this.numFlag=true
         }
      }
   
    },
    removeTags(tags, tag) {
      const index = tags.indexOf(tag);
      if (index !== -1) {
        tags.splice(index, 1);
      }
    },

    adjustTagsWidth() {
      const tagsWidth = this.$refs.tags.$el.scrollWidth;
      this.$refs.tags.$el.style.width = `${tagsWidth}px`;
    },
    changeValue(flag){
      if(!flag){
        this.irnNumber=null
      }
    },
    preventSpace(event) {
      if (event.key === ' ') {
        event.preventDefault(); // Prevent space from being entered
      }
    },
    serialNumberSearch(){
      const payload = {
        serial_num: this.serialNum,
        loc_id:this.currentLoc.value
      };
      this.loader = true;
      this.$store
        .dispatch('assets/getSerialNumDetails', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            if(resp.data.data.length){
              this.serialNumAssetStatus=resp.data.data[0].status;
              this.batchNum=resp.data.data[0].batch_num;
              this.batchSatus=resp.data.data[0].batch_status;
            }
          }else{
            this.makeToast(resp.data.message, 'danger');
          }
        })
        .catch(err => {
          this.loader = false;
          this.makeToast(err.message, 'danger');
        });
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
